/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
];

export const firebaseConfig = {
  apiKey: "AIzaSyCeDcJwIkvoSr7gqmyNIDpfYy75HlYU8aM",
  authDomain: "xpress-learning.firebaseapp.com",
  databaseURL: "https://xpress-learning.firebaseio.com",
  projectId: "xpress-learning",
  storageBucket: "xpress-learning.appspot.com",
  messagingSenderId: "935871443571",
  appId: "1:935871443571:web:4b651bcd60d76febd1f006",
  measurementId: "G-1M09S6N0M9",
};

export const searchPath = "#";
export const servicePath = "https://api.coloredstrategies.com";

export const themeColorStorageKey = "__theme_selected_color";
export const isMultiColorActive = false;
export const defaultColor = "light.purplemonster";
export const isDarkSwitchActive = true;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;
export const colors = [
  "bluenavy",
  "blueyale",
  "blueolympic",
  "greenmoss",
  "greenlime",
  "purplemonster",
  "orangecarrot",
  "redruby",
  "yellowgranola",
  "greysteel",
];
